// Home.js
import React, { useState } from "react";
import "./Home.css";
import TakeAdmission from "./TakeAdmission";
import { IoCall } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
const Home = () => {
  const [showAdmissionForm, setShowAdmissionForm] = useState(false);

  const handleAdmissionButtonClick = () => {
    setShowAdmissionForm(true);
  };

  const handleCloseAdmissionForm = () => {
    setShowAdmissionForm(false);
  };

  return (
    <div>
      <div className="contactbar">
        <div className="questions">Have Any Questions?</div>

        <div className="contactno">
          <a href="tel:+918208937591" className="icon">
            <IoCall />
          </a>
          <a href="tel:+918208937591" className="number">
            +91 8208937591
          </a>
        </div>

        <div className="whatsapp">
          <a href="https://wa.me/918208937591" className="icon1">
            <BsWhatsapp />
          </a>
          <a href="https://wa.me/918208937591" className="number1">
            +91 8208937591
          </a>
        </div>

        <div className="mail">
          <a href="mailto:snehalsp65@gmail.com" className="icon2">
            <FiMail />
          </a>
          <a href="mailto:snehalsp65@gmail.com" className="number2">
            snehalsp65@gmail.com
          </a>
        </div>
      </div>

      <div className="homecontent">
        <div className="tagline">
          "Secure a Position as a <br />
          <span className="highlight">
            <u>
              Full-Stack, End-to-End Artificial Intelligence Software Developer
            </u>
          </span>
          <br />
          with a{" "}
          <span className="highlight1">Salary of One Lakh per Month</span>, Step
          by Step"
        </div>

        <div>
          {!showAdmissionForm && (
            <button
              className="admission-button"
              onClick={handleAdmissionButtonClick}
            >
              Take Admission
            </button>
          )}

          {showAdmissionForm && (
            <TakeAdmission onClose={handleCloseAdmissionForm} />
          )}
        </div>
      </div>

      <div className="videocontent">This space dedicated to video content</div>

      
      <div className="companyname">
        <h1 className="title1">sneh-achievers</h1>
        <h4 className="title2">Investing in Your Success with Confidence</h4>
      </div>

      <div className="content">
        <div className="contenttitle">Why Choose Us?</div>
        <div className="one">
          We are not just a teaching institute; we are a distinct entity engaged
          in both service-based and product-based industries.
        </div>
        <div className="two">
          Our distinguished and experienced board of directors, along with
          global pioneers in skill enhancement, ensure the successful placement
          of students in the IT industry worldwide.ur distinguished and
          experienced board of directors, along with global pioneers in skill
          enhancement, ensure the successful placement of students in the IT
          industry worldwide.
        </div>
        <div className="three">
          Our faculty includes a highly experienced instructor with over 11
          years of expertise in the IT sector and the artificial intelligence
          industry.
        </div>
        <div className="four">
          Our mentor, who is the CEO of his own company, is currently employed
          while also leading two successful enterprises: Sneh-Achievers and
          another one is service and product-based company in the IT industry.
        </div>
        <div className="five">
          As both an employee and employer, our CEO possesses a unique
          perspective and vision, ensuring students are placed in healthy and
          thriving environments.
        </div>
        <div className="six">
          It's not just about teaching and learning; it's about engaging in
          real-time, globally standardized live product development within the
          industry.
        </div>
        <div className="seven">
          Not only does our program provide students with essential skillsets,
          but it also offers valuable industry experience, which is a key
          concern for interviewers
        </div>
        <div className="eight">
          As we develop Proof of Concepts (POCs) for various products in
          collaboration with partner companies, our students gain a competitive
          edge. Successful completion of these projects often leads to
          preferential hiring of our candidates by these companies.
        </div>
        <div className="nine">
          We do not make false promises that everything will happen smoothly or
          that you will be placed easily. However, we can guarantee two things:
          you will definitely achieve your placement goals, and we will support
          you until that time. Essentially, this is a commitment we make with
          you in accordance with Indian law.
        </div>
        <div className="ten">
          Our CEO and teachers have inspiring backgrounds. Starting with a
          mechanical engineering degree and no prior coding knowledge, they have
          risen to the top of their fields. Our CEO now serves as both an
          employee and the head of successful companies, exemplifying how
          dedication and hard work can lead to extraordinary achievements.
        </div>
      </div>

      <div class="technologies">
        <div class="headname">Technologies We Offer</div>
        <div class="tech-grid">
          <div class="techone">
          <div className="techname">React.JS</div>
          </div>
          <div class="techtwo">
            <div className="techname">Python</div>
          </div>
          <div class="techthree">
            <div className="techname">Linux</div>
          </div>
          <div class="techfour">
            <div className="techname">HTML</div>
          </div>
          <div class="techfive">
            <div className="techname">JavaScript</div>
          </div>
          <div class="techsix">
            <div className="techname">CSS</div>
          </div>
          <div class="techseven">
            <div className="techname">Git</div>
          </div>
          <div class="techeight">
            <div className="techname">GitHub</div>
          </div>
          <div class="technine">
            <div className="techname">Docker</div>
          </div>
          <div class="techten">
            <div className="techname">Kubernetes</div>
          </div>
          <div class="techeleven">
            <div className="techname">Jenkins</div>
          </div>
          <div class="techtwelve">
            <div className="techname">Express.js</div>
          </div>
          <div class="techthirteen">
            <div className="techname">MongoDB</div>
          </div>
          <div class="techfourteen">
            <div className="techname">FastAPI</div>
          </div>
          <div class="techfifteen">
            <div className="techname">Jira</div>
          </div>
          <div class="techsixteen">
            <div className="techname">React Native</div>
          </div>
          <div class="techseventeen">
            <div className="techname">Artificial-Intelligence (AI)</div>
          </div>
          <div class="techeighteen">
            <div className="techname">Machine Learning</div>
          </div>
          <div class="technineteen">
            <div className="techname">Deep Learning</div>
          </div>
          <div class="techtwenty">
            <div className="techname">Supervised Learning</div>
          </div>
          <div class="techtwentyone">
            <div className="techname">Unsupervised Learning</div>
          </div>
        </div>
      </div>

      <div className="projectsection">
        <div className="headproject">Our Projects</div>
      </div>
    </div>
  );
};

export default Home;
