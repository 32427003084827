import React, { useState } from "react";
import "./TakeAdmission.css"; // Import CSS file
import API_BASE_URL from './config'; 

const TakeAdmission = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    dob: "",
    education: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${API_BASE_URL}/signin?first_name=${encodeURIComponent(
        formData.firstName
      )}&last_name=${encodeURIComponent(
        formData.lastName
      )}&mobile_number=${encodeURIComponent(
        formData.mobileNumber
      )}&email=${encodeURIComponent(formData.email)}&dob=${encodeURIComponent(
        formData.dob
      )}&education=${encodeURIComponent(
        formData.education
      )}&pay_status=${encodeURIComponent(
        "paid" // Assuming pay_status is always 'paid'
      )}&password=${encodeURIComponent(
        formData.password
      )}&confirm_password=${encodeURIComponent(formData.confirmPassword)}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("**********************")
      const data = await response.json();
      console.log(data)
      console.log("**********************")
      if (response.ok) {
        console.log("Sign up successful!");
        console.log("akshay",response)
        // Redirect or show success message
      } else {
        console.error("Sign up failed:", response.statusText);
        // Handle error
      }
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error
    }
  };

  return (
    <div className="take-admission-container">
      {" "}
      {/* Apply container class */}
      <h2>Admission Form</h2>
      <div>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
      <div className="take-admission-form">
        {" "}
        {/* Apply form class */}
        <h2>Fill Info</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="labeldiv">
              <label>First Name:</label>
            </div>
            <div className="inputdiv">
              <input
                type="text"
                name="firstName"
                placeholder="Enter first name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="labeldiv">
              <label>Last Name:</label>
            </div>
            <div className="inputdiv">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                placeholder="Enter last name"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="labeldiv">
              <label>Mobile Number:</label>
            </div>
            <div className="inputdiv">
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
                placeholder="Enter mobile number"
                maxLength={10}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="labeldiv">
              <label>Email:</label>
            </div>
            <div className="inputdiv">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter email address"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="labeldiv">
              <label>Date of Birth:</label>
            </div>
            <div className="inputdiv">
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <div className="labeldiv">
              <label>Education:</label>
            </div>
            <div className="inputdiv">
              <input
                type="text"
                name="education"
                value={formData.education}
                onChange={handleChange}
                required
                placeholder="Enter higher education"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="labeldiv">
              <label>Password:</label>
            </div>
            <div className="inputdiv">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                placeholder="Enter password"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="labeldiv">
              <label>Confirm Password:</label>
            </div>
            <div className="inputdiv">
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                placeholder="Confirm password"
              />
            </div>
          </div>
          <div className="submit-btn">
            <button className="submit-btn" type="submit">
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TakeAdmission;
