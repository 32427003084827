import React from "react";
import "./Navbarmodule.css"

const Navbar = ({ setPage }) => {
  return (
    <nav className="NavMain">
      <button className="NavButton" onClick={() => setPage("Home")}>Home</button>
      <button className="NavButton" onClick={() => setPage("StudentLogin")}>Student Login</button>
      <button className="NavButton" onClick={() => setPage("PlacementProcess")}>Placement policies & Fee structure</button>
      <button className="NavButton" onClick={() => setPage("Business")}>Business Enquiry</button>
    </nav>
  );
};

export default Navbar;
