import React from 'react';
import "./Business.css";

const Business = () => {
  return (
    <div className="business-container">
      <h1>Business Enquiry</h1>
      
      <div className="enquiry-section">
   
    <h2>Connect with us to hire skilled students</h2>
    
    <form className='form1'>
        <label htmlFor="email">Email Address:</label>
        <input type="email" id="email" name="email" placeholder="Enter your email" required />
        
        <label htmlFor="phone">Contact Number:</label>
        <input type="tel" id="phone" name="phone" placeholder="Enter your contact number" required />
        
        <label htmlFor="Name of the Company">Name of the Company</label>
        <input type="text" id="Name of the Company" name="Name of the Company" placeholder="Enter your Company Name" required />
        
        <button type="submit">Submit</button>
    </form>
</div>

      
<div className="enquiry-section">
  <h2>General Business Enquiries</h2>
  <p>If you have any questions or need further information, please don't hesitate to contact us.</p>
  <div className="contact-info">
    <p><strong>Email:</strong> <a href="mailto:snehalsp65@gmail.com">snehalsp65@gmail.com</a></p>
    <p><strong>Contact Number:</strong> <a href="tel:+918208937591">+91 82089 37591</a></p>
  </div>
</div>



<div className="address-box">
      <h2>Our Location</h2>
      <a 
        href="https://www.google.com/maps/search/?api=1&query=Gate+No.+03+Lodha+Crown+Taloja+near+Khoni+Phata,+Pin-code+421204" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Gate No. 03, Lodha Crown Taloja, near Khoni Phata, Pin-code 421204
      </a>
    </div>

    </div>
  );
};

export default Business;



